

.print-block, .print-inline {
  display: none !important;
}

.ref {
  break-inside: avoid;
}

@media print {
  .print-hide {
    display: none !important;
  }
  .print-block {
    display: block !important;
  }
  .print-inline {
    display: inline !important;
  }

  .login-popup,
  .subscription-bar,
  .tabs a,
  .tabs .settings,
  .sidebar,
  .toggle,
  .actions,
  .blog .icon,
  app-comment-reply,
  .mobile-tab-select,
  footer {
    display: none !important;
  }

  hr {
    box-shadow: none !important;
  }

  body, .container {
      background: none !important;
  }

  .tabs,
  .print-images,
  .thumbnail,
  .logo {
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .tabs {
    min-height: 0;
    background-color: unset !important;
    .logo {
      background-image: url("~/assets/jasper-light.png") !important;
    }
  }

  .full-page .link {
    display: none;
  }

  .backup {
    .list-container .list-number {
      max-height: unset;
      line-height: unset;
    }
  }

  .image-expand {
    max-width: 100% !important;
  }

  .md code {
    border: unset !important;
    background-color: unset !important;
  }

  /**
* Gruvbox light theme
*
* Based on Gruvbox: https://github.com/morhetz/gruvbox
* Adapted from PrismJS gruvbox-dark theme: https://github.com/schnerring/prism-themes/blob/master/themes/prism-gruvbox-dark.css
*
* @author Michael Schnerring (https://schnerring.net)
* @version 1.0
*/
  body.dark-theme {
    code[class*="language-"],
    pre[class*="language-"] {
      color: #3c3836; /* fg1 / fg */
      font-family: Consolas, Monaco, "Andale Mono", monospace;
      direction: ltr;
      text-align: left;
      white-space: pre;
      word-spacing: normal;
      word-break: normal;
      line-height: 1.5;

      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;

      -webkit-hyphens: none;
      -moz-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
    }

    pre[class*="language-"]::-moz-selection,
    pre[class*="language-"] ::-moz-selection,
    code[class*="language-"]::-moz-selection,
    code[class*="language-"] ::-moz-selection {
      color: #282828; /* fg0 */
      background: #a89984; /* bg4 */
    }

    pre[class*="language-"]::selection,
    pre[class*="language-"] ::selection,
    code[class*="language-"]::selection,
    code[class*="language-"] ::selection {
      color: #282828; /* fg0 */
      background: #a89984; /* bg4 */
    }

    /* Code blocks */
    pre[class*="language-"] {
      padding: 1em;
      margin: 0.5em 0;
      overflow: auto;
    }

    :not(pre) > code[class*="language-"],
    pre[class*="language-"] {
      background: #f9f5d7; /* bg0_h */
    }

    /* Inline code */
    :not(pre) > code[class*="language-"] {
      padding: 0.1em;
      border-radius: 0.3em;
    }

    .token.comment,
    .token.prolog,
    .token.cdata {
      color: #7c6f64; /* fg4 / gray1 */
    }

    .token.delimiter,
    .token.boolean,
    .token.keyword,
    .token.selector,
    .token.important,
    .token.atrule {
      color: #9d0006; /* red2 */
    }

    .token.operator,
    .token.punctuation,
    .token.attr-name {
      color: #7c6f64; /* fg4 / gray1 */
    }

    .token.tag,
    .token.tag .punctuation,
    .token.doctype,
    .token.builtin {
      color: #b57614; /* yellow2 */
    }

    .token.entity,
    .token.number,
    .token.symbol {
      color: #8f3f71; /* purple2 */
    }

    .token.property,
    .token.constant,
    .token.variable {
      color: #9d0006; /* red2 */
    }

    .token.string,
    .token.char {
      color: #797403; /* green2 */
    }

    .token.attr-value,
    .token.attr-value .punctuation {
      color: #7c6f64; /* fg4 / gray1 */
    }

    .token.url {
      color: #797403; /* green2 */
      text-decoration: underline;
    }

    .token.function {
      color: #b57614; /* yellow2 */
    }

    .token.regex {
      background: #797403; /* green2 */
    }

    .token.bold {
      font-weight: bold;
    }

    .token.italic {
      font-style: italic;
    }

    .token.inserted {
      background: #7c6f64; /* fg4 / gray1 */
    }

    .token.deleted {
      background: #9d0006; /* red2 */
    }
  }

}
