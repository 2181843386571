// Dark Theme
body.dark-theme {
  --bg: #343434;
  --bg-accent: rgba(90, 90, 90, 0.05);
  --bg-hover: rgba(255, 255, 255, 0.1);
  --bg-active: rgba(255, 255, 255, 0.4);
  --text: #c9c9c9;
  --visited: #c66acb;
  --link: #5769c2;
  --active: #dc5c5c;
  --info: #969696;
  --tag: #af5800;
  --toggle: #525252;
  --toggle-accent: #777;
  --toggle-active: #888;
  --form-bg: #3b3b3b;
  --border: lightgrey;
  --border-accent: rgba(255, 255, 255, 0.4);
  --unselected-tab: grey;
  --error: #dc5c5c;
  --error-bg: #231010;
  --warning: #967f46;
  --mod: #4d9339;
  --card: #3d3d3d;
  --card-dragging: #4d4d4d;
  --placeholder: #2c2c2c;
  --deleting: #dc5c5c;
  --quote: #969696;

  @media not print {
    color-scheme: dark;
    color: var(--text);
    @supports not (color-scheme: dark) {
      input[type=email], input[type=url], input[type=text], input[type=number], input[type=search], textarea {
        appearance: none;
        color: var(--text);
        background-color: rgb(59, 59, 59);
        border: 1px solid  rgb(133, 133, 133);
        border-radius: 2px;
        input:disabled {
          color: rgb(170, 170, 170);
        }
      }
      input[type=checkbox] {
        background-color: rgb(59, 59, 59);
      }
      button {
        color: var(--text);
        appearance: none;
        background-color: rgb(107, 107, 107);
        border: 2px solid rgb(107, 107, 107);
        border-radius: 2px;
        &:disabled {
          color: rgb(170, 170, 170);
        }
      }

      &:not(.macos) {
        input, textarea {
          &::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        }
        select:not(:disabled) {
          color: var(--text);
          background-color: rgb(59, 59, 59);
          border: 1px solid rgb(118, 118, 118);
          border-radius: 2px;
        }
      }
    }
  }

  & {
    background-color: var(--bg);
    background-image: url("~/assets/bg-dark.png");
    background-blend-mode: soft-light;
  }
  figure::backdrop, .editor.fullscreen app-md {
    background-color: var(--bg);
    background-image: url("~/assets/bg-dark.png");
    background-blend-mode: soft-light;
  }

  app-root > .container {
    background: radial-gradient(circle at center, transparent 0, rgba(0, 0, 0, 0.3));
  }

  :link, .link-style {
    color: var(--link);
  }

  :not(.actions) :visited {
    color: var(--visited);
  }

  a:active {
    color: var(--text);
  }

  .toolbar {
    label.on {
      backdrop-filter: contrast(0.1) brightness(0.6) blur(1.5px);
    }
  }

  .warning {
    color: var(--warning);
  }

  .subscription-bar {
    background-color: var(--bg);
    border-bottom: 1px solid black;

    :link, :visited, a:active {
      color: var(--text);
    }

    .current-page {
      color: var(--active);
    }

    .light-toggle {
      background-color: rgba(255, 255, 255, 0.1);
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .tabs {
    background-color: rgba(100, 100, 100, 0.3);
    .logo {
      background-image: url("~/assets/jasper-dark.png");
    }

    h5 {
      text-decoration: brown;
    }

    & > a {
      &:hover {
        background-color: #a7a7a7;
      }

      &.current-tab {
        color: var(--active);
        background-color: #252525;
        background-image: url("~/assets/bg-dark.png");
        background-blend-mode: soft-light;
        border-top: 1px solid var(--border);
        border-left: 1px solid var(--border);
        border-right: 1px solid var(--border);
        border-bottom: 1px solid transparent;
        &:nth-child(4) {
          background-color: #262626;
        }
        &:nth-child(5) {
          background-color: #272727;
        }
        &:nth-child(6) {
          background-color: #282828;
        }
        &:nth-child(7) {
          background-color: #292929;
        }
        &:nth-child(8) {
          background-color: #292929;
        }
        &:nth-child(9) {
          background-color: #292929;
        }
        &:nth-child(10) {
          background-color: #292929;
        }
        &:nth-child(11) {
          background-color: #282828;
        }
        &:nth-child(12) {
          background-color: #282828;
        }
      }
    }

    select:hover {
      background-color: #a7a7a7;
    }
  }

  .sidebar {
    background-color: var(--bg-accent);
    backdrop-filter: grayscale(1) contrast(0.2) brightness(0.4) blur(1px);
    box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  }

  details.advanced summary > span {
    &:after {
      filter: drop-shadow(0 0 2px var(--bg));
    }
  }

  .editor:focus-within, .bubble, .plugins-form > *, blockquote, .full-page .todo-list {
    background-color: var(--bg-accent);
    backdrop-filter: blur(1px);
  }

  .thumbnail, .image-expand, video, .embed-expand, .qr-expand {
    filter: drop-shadow(0px 0px 0.5px #ffffff);
  }

  .twitter iframe {
     filter: none !important;
   }

  .thumbnail.default-thumbnail {
    filter: none;
  }

  .comment-children {
    border-left: 1px dotted rgba(211, 211, 211, 0.3);
  }

  .thread > .ref-list > .list-container > .ref,
  .md .ref.plugin_thread:not(.ref-list-item),
  .thread .ref.plugin_thread:not(.ref-list-item),
  .thread.ref {
    --thread-sent: rgba(32, 128, 255, 0.15);
    --thread-recieved: rgba(255, 255, 255, 0.1);
    box-shadow: 1px 1px 2.5px #000;
  }

  .summary-box {
    background-color: var(--bg-accent);
    backdrop-filter: blur(1px);
  }

  footer hr {
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3),
                0 6px 5px 1px rgba(0, 0, 0, 0.1);
  }

  .kanban {
    .column-title, .swim-lane-title {
      background-color: var(--bg-accent);
      backdrop-filter: blur(1px);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
      &:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2),
                    0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  .kanban-card {
    &.cdk-drag-preview {
      background-color: var(--card-dragging);
    }
  }

  .todo-item {
    &.cdk-drag-preview {
      background-color: var(--card-dragging);
    }
  }

  .backgammon-board {
    --bg: rgba(178, 103, 41, 0.2);
    --black: #111;
    --red: #633;
    --play: rgba(255, 255, 0, 0.3);
    --brown: #431;
    --brown-alt: #653;
    --glow: rgba(200, 200, 200, 0.5);
    --move: rgba(0, 128, 0, 0.5);
  }

}
