body.darwin, body.ios {
  // See `mobile.scss`
  $mobile-width: 740px;
  @media (max-width: $mobile-width) {
    .subscription-bar {
      .light-toggle > * {
        margin-top: -5px
      }
    }
  }
}
body.safari {
  input, textarea {
    background-color: var(--form-bg);
  }
  .toggle {
    line-height: 22px;
  }
  .actions-toggle {
    line-height: 25px;
  }
}

body.darwin.safari {
  .subscription-bar {
    .back-button {
      padding-top: 2px;
    }
    .light-toggle {
      padding: 2px 20px 15px 22px;
    }
  }
  input:not([type=checkbox]):not([type=radio]) {
    height: 28px;
  }
  .actions, .advanced-actions {
    input, button {
      position: relative;
      top: 1px;
      height: 16px !important;
      font-size: 100%;
    }
  }
  .form-array, .buttons {
    button {
      min-height: 28px !important;
      min-width: 32px !important;
    }
  }
  .sidebar .controls select {
    min-height: 22px;
    position: relative;
    top: 2px;
  }
  .form,
  formly-list-section .form-array > formly-field > formly-group {
    select {
      font-size: 21px;
    }
    button {
      min-height: 28px !important;
      padding: 1px 6px;
    }
    .shrink select {
      margin-top: 4px;
    }
    .nested-form,
    .field,
    formly-field,
    formly-group,
    formly-wrapper-form-field,
    formly-list-section {
      & > label {
        line-height: 28px;
      }
    }
    & > label {
      line-height: 28px;
    }
  }
  select.big {
    font-size: 21px;
  }
  .search {
    input[type=search] {
      margin-top: 0;
    }
  }
  .search.centered {
    position: relative;
    top: 50%;
    input[type=search] {
      transform: translateY(-50%);
    }
  }
}

body.ios {
  input:not([type=checkbox]):not([type=radio]) {
    height: 28px;
  }
  button[type=submit] {
    color: revert;
  }
  button {
    min-width: 30px !important;
    min-height: 30px !important;
    padding: 1px 6px;
  }
  .controls button {
    min-width: unset !important;
  }
  .toolbar, .form-array {
    button {
      min-width: 56px !important;
    }
  }
  .controls {
    line-height: 30px;
  }
  select {
    height: 30px !important;
  }
  .shrink select {
    margin-top: 4px;
  }
  .actions, .advanced-actions {
    input {
      height: 20px !important;
    }
  }
  .toggle {
    .toggle-x {
      top: -1px;
    }
  }
  .form,
  formly-list-section .form-array > formly-field > formly-group {
    .nested-form,
    .field,
    formly-field,
    formly-group,
    formly-wrapper-form-field,
    formly-list-section {
      & > label {
       line-height: 30px;
      }
    }
    & > label {
      line-height: 30px;
    }
  }
  body.fullscreen {
    & > * {
      display: block;
    }
  }
}
