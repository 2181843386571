// Common to all themes
body {
  --bg: white;
  --bg-accent: rgba(0, 0, 0, 0.05);
  --bg-hover: rgba(255, 255, 255, 0.2);
  --bg-active: rgba(255, 255, 255, 0.4);
  --text: black;
  --blue: rgba(207, 221, 255, 0.7);
  --active: #da0000;
  --info: gray;
  --tag: #3d3dbb;
  --toggle: #525252;
  --toggle-accent: #777;
  --toggle-active: #888;
  --toggle-border: transparent;
  --tag-accent: #f3ebc7;
  --form-bg: #3b3b3b;
  --border: black;
  --border-accent: rgba(0, 0, 0, 0.4);
  --border-light: rgba(0, 0, 0, 0.2);
  --unselected-tab: #b3b9c4;
  --error: #da0000;
  --error-bg: #ffa9a9;
  --warning: #c1c500;
  --mod: #136500;
  --card: #f3ebc7;
  --placeholder: #efebdf;
  --deleting: #ffa9a9;
}

html, body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 16px;
}

body.fullscreen {
  overscroll-behavior: none;
  & > * {
    display: none;
  }
  & > .cdk-overlay-container {
    display: block;
  }
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
  font-size: 14px;
}
textarea {
  min-width: 20px;
  min-height: 100px;
  max-width: 100vw;
  max-height: 100vh;
}

.debug {
  .subscription-bar:before {
    color: var(--error);
    font-size: 14px;
    content: 'DEBUG!';
  }
  footer p {
    &.version-debug {
      font-size: 0;
    }
    min-height: 72px;
    line-height: 72px;
    background-repeat: repeat-x !important;
    background-image: url("~/assets/debug.png") !important;
    border-top: 2px solid var(--border);
    border-bottom: 2px solid var(--border);
  }
}

.error {
  font-size: 80%;
  color: var(--error);
}

.error-404, .error-403 {
  color: var(--error);
}

.error-404.submit-button {
  display: inline-block;
  margin: auto;
  color: var(--text);
  border: 1px solid var(--border);
}

.hidden-without-removing {
  pointer-events: none;
  user-select: none;
  position: absolute;
  opacity: 0;
}

.tag {
  color: var(--tag) !important;
}

.pinned .link {
  :link, :visited, :active {
    color: var(--mod) !important;
  }
}

.update {
  font-size: 80%;
  color: var(--text);
  text-decoration: underline;
}

.log {
  padding: 8px;
  border-radius: 4px;
  background-color: var(--card);
  backdrop-filter: blur(1px);
  progress {
    width: 100%;
  }
}

hr {
  border: none;
  border-top: 1px groove var(--border);
}

.summary-box {
  max-width: 60em;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px dashed transparent;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
}

.login-popup {
  background-color: var(--error-bg);
}

.resize-handle {
  touch-action: none;
  position: relative;
  &:before {
    z-index: 10;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyIiBjeT0iMTQiIHI9IjEuNSIvPjxjaXJjbGUgY3g9IjgiIGN5PSIxNCIgcj0iMS41Ii8+PGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjEuNSIvPjxjaXJjbGUgY3g9IjE0IiBjeT0iMiIgcj0iMS41Ii8+PGNpcmNsZSBjeD0iMTQiIGN5PSIxNCIgcj0iMS41Ii8+PGNpcmNsZSBjeD0iMTQiIGN5PSI4IiByPSIxLjUiLz48cGF0aCBkPSJtMCAwaDM2djM2aC0zNnoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=");
    position: absolute;
    right: -2px;
    bottom: -2px;
    filter: drop-shadow(0.5px 0.5px 1.25px white);
  }
}

.nowrap {
  white-space: nowrap;
}

.spacer {
  pointer-events: none;
  flex-grow: 1;
}

.dn {
  display: none !important;
}

:link, .fake-link {
  text-decoration: none;
}

.recolour {
  filter: grayscale(1) sepia(1) saturate(1.5) brightness(1.5) hue-rotate(180deg);
}

:link:hover,
.fake-link:hover {
  text-decoration: underline;
}

.no-underline {
  &, &:active, &:hover, &:visited {
    text-decoration: none;
  }
}

.fake-link {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

label.big {
  padding-top: 5px
}

button {
  padding: 1px 6px;
  min-width: 28px !important;
  min-height: 22px !important;
}

.form-array, .buttons {
  & > button {
    min-width: 32px !important;
    min-height: 24px !important;
  }
}

.form-array, .controls {
  & > button {
    min-height: 22px;
    padding: 0 2px;
  }
}

.dropping {
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  background-color: var(--bg-hover);
  * {
    pointer-events: none !important;
  }
}

.preview {
  cursor: text;
  pointer-events: all;
  text-decoration: none;
}

.hotkey .preview {
  cursor: pointer;
  text-decoration: underline;
}

input:not([type=checkbox]):not([type=radio]) {
  height: 24px;
  &.big {
    height: 29px;
  }
  &[disabled] {
    border: 2px solid var(--bg);
    text-decoration: underline;
    font-weight: bold;
  }
}

input:not([type=checkbox]):not([type=radio]),
textarea {
  box-sizing: border-box;
  padding-block: 2px;
  padding-inline: 8px;
}

button,
input,
textarea,
select {
  color: var(--text);
}

select {
  height: 24px;
  margin: 0 auto;

  &.big {
    outline: none !important;
    cursor: pointer;
    min-width: 72px;
    min-height: 29px;
    line-height: 29px;
    font-size: 16px;
    padding: 3px 6px;
  }
}

.wide {
  width: 100%;
}

.padded {
  padding: 4px;
}
.padded > * {
  margin: 4px;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(4px);
}

.cdk-overlay-pane {
  pointer-events: none;
  * {
    pointer-events: all;
  }
}

.cdk-drop-list-dragging,
.cdk-drop-list-receiving {
  cursor: grabbing !important;
}

.floating-ribbons > * {
  transition: right 0.1s cubic-bezier(.47,1.64,.8,1.2);
  transition-delay: 0.1s;
}

.floating-toggles, .floating-ribbons {
  position: absolute;
  pointer-events: none;
  * {
    pointer-events: all;
  }

  &.big * {
    margin: 16px;
    font-size: 300%;
  }
}

.editor {
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  &.add-button {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    backdrop-filter: none !important;
    box-shadow: none !important;
  }

  &.stacked {
    display: block;
  }

  .editor-container {
    display: contents;
  }

  .text-wrapper {
    position: relative;
  }

  .floating-ribbons {
    right: 0;
    top: 32px;
  }

  .toolbar {
    display: none;
    flex-direction: row-reverse;
    & > :first-child {
      margin-right: 0;
    }
    & > * {
      flex-shrink: 0;
      height: 28px;
      min-width: 32px !important;
      margin: 4px 4px 8px;
    }
    & > button {
      min-width: 28px;
    }
    label {
      border: 1px dashed var(--border) !important;
    }
    label.on {
      border: 0.5px solid var(--border) !important;
    }
    .toolbar-toggle {
      position: relative;
      background-color: var(--toggle);
      border-radius: 6px;
      height: 28px;
      white-space: nowrap;
      &::before {
        content: ' ';
        position: absolute;
        width: 26px;
        height: 26px;
        top: 0;
        left: -1px;
        transition: left 300ms ease-in-out;
        border: 1px dashed var(--border);
        border-radius: 6px;
        pointer-events: none;
      }
      button {
        border: none;
        background: transparent;
        user-select: none;
        cursor: pointer;
        margin: 0;
        padding: 5px 0;
      }
    }
  }
  .toolbar-toggle-1 {
    &::before {
      left: 28px !important;
    }
  }
  .toolbar-toggle-2 {
    &::before {
      left: 56px !important;
    }
  }
  .toolbar-toggle-3 {
    &::before {
      left: 84px !important;
    }
  }
  .toolbar-toggle-4 {
    &::before {
      left: 112px !important;
    }
  }
  .toolbar-toggle-5 {
    &::before {
      left: 140px !important;
    }
  }

  &:focus-within, &.editing {
    .toolbar {
      display: flex;
    }
  }
}

.editor-label, .add-plugins-label {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.floating-ribbons {
  user-select: none;
  font-size: 80%;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 32px;
  & > div {
    position: relative;
    white-space: nowrap;
    cursor: pointer;
    right: -100%;
    margin: 4px !important;
    span {
      margin-right: auto;
      display: inline-block;
      max-width: 128px;
      text-overflow: ellipsis;
      overflow: hidden;
      border-radius: 2px;
      padding: 2px;
      border: 1px dashed var(--border);
    }
    &.on {
      right: 16px;
      text-align: right;
      span {
        backdrop-filter: blur(1.5px);
        border: 0.5px solid var(--border);
      }
    }
  }
}

.editor:focus-within, .bubble, .plugins-form > *, blockquote, .full-page .todo-list {
  border-radius: 8px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
}

.bubble, .plugins-form > *, blockquote {
  margin-top: 8px;
  padding: 8px;
  border: 1px dashed transparent;

  hr {
    border: none;
    border-top: 0.5px dotted var(--border);
    position: relative;
    left: -8px;
    width: calc(100% + 16px);
  }

  &:first-child {
    margin: 0;
  }
}

.bubble, .plugins-form > * {
  &:hover {
    border: 1px dashed var(--border);
  }
}

.bubble {
  .toolbar {
    display: flex;
  }
}

.toolbar .editor-toggle {
  user-select: none;
  font-size: 80%;
  font-weight: bold;
  position: relative;
  label {
    position: relative;
    top: -3px;
    transform: rotate(-3deg);
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    max-width: 64px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 2px;
    padding: 2px;
    border: 1px solid transparent;
    transition: all 0.1s cubic-bezier(.47,1.64,.8,1.2);
    transition-delay: 0.1s;
    &.on {
      top: 16px;
      transform: rotate(0);
    }
    &:not(.on):hover {
      transform: rotate(-4deg) scale(1.05);
    }
  }
}

.editor-help-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    padding: 16px;
    max-width: 300px;
    border-radius: 2px;
    margin: 8px;
    background-color: var(--card);
    border: 1px solid transparent;
    &:hover {
      border: 1px solid var(--border);
    }
  }
}

video.qr-preview {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}

.context-menu {
  user-select: none;
  border-radius: 2px;
  padding: 8px;
  white-space: nowrap;
  background-color: var(--card);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
  & > * {
    display: block;
    border-radius: 2px;
    margin: 4px;
    padding: 4px;
    cursor: pointer;
    &:hover {
      background-color: var(--bg-hover);
    }
  }
}

.parent-link {
  max-width: 75em;
  display: block;
  font-size: 80%;
  font-weight: bold;
  color: var(--info);
  text-align: center;
  padding: 16px;
  margin: 8px;
}


.submit-button {
  white-space: nowrap;
  text-transform: capitalize;
  border: 1px solid var(--border);
  border-radius: 2px;
  margin: 4px 0;
  padding: 4px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--bg-hover);
    &:active {
      background-color: var(--bg-active);
    }
  }
  label {
    margin-left: 16px;
  }
  * {
    cursor: pointer;
  }
}

.warning {
  font-size: 80%;
  font-style: italic;
  padding: 10px;
}

.error-404, .error-403, .ban-message {
  font-size: 300%;
  text-align: center;
}

.error-404.submit-button {
  font-size: 100%;
  white-space: nowrap;
  text-transform: capitalize;
  border: 1px solid transparent;
  padding: 4px;
  cursor: pointer;
  overflow: hidden;
}

.add-more,
.overwrite.warning,
.force.error {
  display: flex;
  align-items: center;
  font-size: 100% !important;
  margin-right: 16px !important;
  padding: 0 !important;
  input {
    margin: 4px;
  }
}

.tag,
.icon:not(:empty) {
  margin: 0 4px;
  line-height: 10px;
  cursor: pointer;
  font-style: normal;
  & + .tag,
  & + .icon {
    margin-left: 0;
  }
}

.hotkey .filter-toggle {
  display: inline-block;
  animation-duration: 1s;
  animation-delay: -0.5s;
  animation-name: unfilter;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes unfilter {
  from {
    transform: translateY(-2px) rotate(20deg);
  }

  50% {
    transform: translateY(3px) rotate(0);
  }

  to {
    transform: translateY(-2px) rotate(-20deg);
  }
}

.deleted {
  text-decoration: line-through;
  opacity: 0.5;
}

.subscription-bar {
  user-select: none;
  font-size: 10px;
  border-bottom: 1px solid transparent;
  padding: 4px 8px 2px 0;
  background-color: var(--bg);
  border-bottom: 1px solid black;

  :link, :visited, a:active {
    color: var(--text);
  }

  .current-page {
    color: var(--active);
  }

  .back-button {
    display: none;
    cursor: pointer;
    font-size: 18px;
    margin: -1px 0;
    padding-left: 4px;
    line-height: 18px;
    filter: grayscale(1);
  }

  .drafts {
    margin: 0 10px;
    cursor: pointer;
    padding-top: 2.5px;
    position: relative;
    transition: transform 0.15s ease-in-out;
    transform-origin: center;
    transform:  scale(1.25);
    &:hover {
      transform:  scale(1.75);
    }
  }

  .preferences {
    white-space: nowrap;
    margin: 0 10px;
    cursor: pointer;
    padding-top: 2.5px;
    position: relative;
    transition: transform 0.15s ease-in-out;
    transform-origin: center;
    transform:  scale(1.25);
    &:hover {
      transform:  scale(1.75);
    }
  }

  a {
    text-transform: uppercase;
    padding: 2px;
  }

  .current-page {
    font-weight: bold;
  }

  .subs {
    font-size: 12px;
    margin-top: 2px;
    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
    *:first-child{
      margin-left: 10px;
    }
    *:last-child {
      margin-right: 10px;
    }
  }

  .light-toggle {
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    padding: 2px 15px 15px 20px;
    border-radius: 16px;
    transition: all 0.2s;
    border: 0.5px solid transparent;
    &:hover {
      border: 0.5px dashed var(--border);
    }
    div {
      position: absolute;
      transition: all 0.4s ease-in-out;
      &.moon.lights-on {
        transform: translate(-18px);
      }
      &.light.lights-off {
        transform: rotate(-180deg);
      }
      &.light.lights-on {
        transform: translate(-18px);
      }
      &.moon.lights-off,
      &.light.lights-on {
        opacity: 1;
      }
      &.moon.lights-on,
      &.light.lights-off {
        opacity: 0;
      }
    }
  }
}

.tabs {
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  min-height: 60px;
  height: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100vw;
  border-bottom: 1px solid var(--border);

  &.measuring > a:not(.logo):not(.current-tab) {
    position: absolute;
    opacity: 0;
  }

  .logo {
    width: 120px;
    height: 56px;
    background-color: transparent !important;
    border: none !important;
    background-position: 4px 0;
    background-repeat: no-repeat;
  }

  & > * {
    align-self: flex-end;
  }

  &.empty > a:not(.logo),
  &.empty > app-mobile-tab-select {
    mask-image: gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  }

  h5 {
    margin: 4px;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.floating-tabs {
    &>a:not(.logo) {
      display: none !important;
    }
    .current-tab {
      display: block;
      border: 1px solid var(--border) !important;
      margin: 4px;
      flex-basis: 100%;
      border-radius: 4px;
    }
  }

  .mobile-tab-select {
    margin: 0 4px;
    select {
      appearance: none;
      border-radius: 0;
      font-size: 16px;
      height: 25px !important;
      line-height: 25px;
      width: 44px;
      padding: 0 10px;
      text-align: center;
      position: relative;
      outline: none !important;
      cursor: pointer;
      background-color: var(--unselected-tab);
      border: none;
    }
  }

  & > a:not(.logo) {
    display: inline-block;
    position: relative;
    bottom: 0;

    margin: 0 4px;
    padding: 2px 8px;
    font-weight: bold;
    line-height: 21px;
    background-color: var(--unselected-tab);

    &:link:hover,
    &.fake-link:hover {
      text-decoration: none;
    }

    &:link, &:visited, &:active, &.fake-link {
      color: black !important;
    }

    &.current-tab {
      color: var(--active) !important;
      display: inline-block !important;
      background-color: var(--bg);
      bottom: -1px;
      border: 1px solid var(--border);
      border-bottom: 1px solid transparent;
    }
  }

  .settings {
    margin-left: auto;
    border-top-left-radius: 8px;
    display: inline-block;
    justify-self: flex-end;
    user-select: none;

    font-size: 80%;
    line-height: 15px;
    padding: 4px;
    font-weight: bold;
    background-color: var(--unselected-tab);

    .author {
      color: var(--tag) !important;
    }

    & > a {
      &:link, &:visited, &:active, &.fake-link {
        color: black !important;
      }
    }
  }
}

.sidebar {
  position: relative;
  float: right;
  overflow: hidden;
  transition: width .5s;
  width: 416px;
  margin-bottom: 100px;
  margin-left: 4px;
  border-bottom-left-radius: 4px;

  &.floating {
    float: none;
    position: absolute;
    right: 0;
  }

  .toggle {
    margin-top: 3px;
  }

  .row {
    padding: 0 2px;
    app-search, app-filter, app-sort {
      flex-grow: 1;
    }
  }

  app-filter {
    margin-right: 8px;
  }

  details.advanced summary {
    margin: 4px -8px;
  }

  & > * {
    margin: 4px;
  }

  &:not(.expanded) {
    width: 40px;
    .tag-header, .ui, .md, .sidebar-link, .ext-text, .breadcrumbs {
      display: none;
    }
  }

  input[type=search] {
    margin-top: 3px;
    height: 26px;
    width: 368px;
  }

  .controls {
    select, button {
      height: 22px;
    }
  }

  app-query {
    input, button {
      height: 27px !important;
    }
  }

  .breadcrumbs {
    text-align: center;
    * {
      margin: 0;
    }
  }

  .show-remotes {
    display: flex;
    justify-content: flex-end;
    label {
      align-self: center;
      font-size: 75%;
      margin-right: 10px;
      line-height: 13px;
    }
  }

  .button-bar {
    display: flex;
    flex-direction: column;
  }

  .submit-button {
    margin: 4px 0;
  }

  .tag-header {
    text-align: center;

    .subscribe, .bookmark {
      margin: 10px;
    }

    .alarm {
      margin: 10px 0 10px -8px;
      padding: 1px;
    }
  }

  .sidebar-link {
    display: block;
    text-align: center;
    font-size: 80%;
  }

  .ext-text {
    padding: 4px;
    a {
      display: inline-block;
      margin: 2px 10px;
    }
  }

  .bulk.actions .action > * {
    display: block;
  }

  .md table {
    width: 100%;
    table-layout: fixed;
  }

  .ref, .ext {
    white-space: nowrap;
    .info, .actions, .advanced-actions * {
      font-size: 16px;
      line-height: 28px;
      height: 28px;
      padding-top: 0;
      padding-bottom: 0;
      overflow-y: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      a, .fake-link {
        line-height: 28px;
      }
    }
  }

  .ref {
    max-width: 100vw;
    .row {
      max-width: calc(100vw - 12px);
    }
    .info, .actions {
      height: 32px;
      margin-left: -8px;
      padding: 0 8px;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));

      &.expanded {
        height: 32px;
        padding-top: 2px;
      }
    }
  }
}

details.advanced {
  transition: max-height 1s;
  max-height: 37px;
  overflow: hidden;

  summary {
    display: block;
    text-align: right;
    &:hover {
      background-color: var(--bg-hover);
      &:active {
        background-color: var(--bg-active);
      }
    }
    &::marker,
    &::-webkit-details-marker {
      display: none;
      opacity: 0;
    }
    &:first-of-type {
      list-style-type: none;
    }
    & > span {
      display: block;
      cursor: pointer;
      padding: 4px;
      border: 1px solid var(--border);
      border-left: none;
      border-right: none;
      transition: background-color 0.2s;
      &:after {
        margin: 0 8px;
        content: '🔅';
        display: inline-block;
        transition: transform 0.4s;
        transform: rotate(0);
      }
    }
  }
}

details.advanced[open] {
  max-height: 1000px;
  summary > span {
    &:after {
      content: '🔆';
      transform: rotate(-120deg);
    }
  }
  .summary-closed {
    display: none;
  }
}

details.mini:not([open]) {
  float: right;
  margin: -4px 0 4px 0 !important;
  summary > span {
    border: none !important;
  }
  .summary-open {
    display: none;
  }
}

.row {
  display: flex;

  &.center {
    justify-content: center;
  }
  .stretch {
    flex-basis: 100%;
  }
}

.col {
  display: flex;
  flex-direction: column;
  .center {
    text-align: center;
  }
}

.list-container {
  display: grid;
  grid-template-columns: min-content auto;

  .list-number, .label {
    color: var(--info);
    text-align: right;
    white-space: nowrap;
    padding: 0 10px;
    font-weight: bold;
    height: 100%;
    min-width: 18px;
    max-height: 57px;
    line-height: 57px;
  }
}

.backup {
  .list-container .list-number {
    max-height: 48px;
    line-height: 48px;
  }
}

.grow {
  flex: 1;
  flex-basis: 100% !important;
  display: flex;
  flex-direction: column;
  input {
    flex: 1;
    flex-basis: 100% !important;
  }
}

progress.grow {
  height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.max-form-width,
.form,
formly-list-section .form-array > formly-field > formly-group {
  max-width: 600px;
}

.form,
formly-list-section .form-array > formly-field > formly-group {
  display: grid !important;
  grid-template-columns: min-content auto;
  grid-auto-rows: min-content;
  padding: 10px;
  .shrink select {
    margin-top: 2px;
  }
  .nested-form,
  .field,
  formly-field,
  formly-group,
  formly-wrapper-form-field,
  formly-list-section {
    margin: 0;
    display: contents;
    & > * {
      margin: 4px;
    }
    & > div:empty,
    & > span:empty {
      height: 0;
      margin: 0;
    }
    & > label {
      text-align: right;
      line-height: 24px;
    }
  }
  & > * {
    margin: 4px;
  }
  & > div:empty,
  & > span:empty {
    height: 0;
    margin: 0;
  }
  & > label {
    text-align: right;
    line-height: 24px;
  }
  label {
    user-select: none;
    white-space: nowrap;
  }
  .block-form {
    display: flex;
    align-items: center;
  }
}

.show-drops {
  .nested-form,
  .field,
  formly-field,
  formly-group,
  formly-wrapper-form-field,
  formly-list-section {
    & > div:empty,
    & > span:empty {
      height: unset !important;
      margin: unset !important;
    }
  }
}

.form-group > * {
  margin: 4px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.form-array, .buttons, .controls {
  display: flex;
  flex: 1;
  &.right {
    justify-content: right;
  }
  & > * {
    margin-right: 4px;
    &:last-child, input[file] {
      margin-right: 0 !important;
    }
  }
  &.skip-margin * {
    margin-right: 0 !important;
  }
  input {
    flex: 1;
    flex-basis: 100%;
  }
  select {
    margin-left: 0;
  }
}

.drag-handle {
  &:after {
    content: '≡';
  }
  align-self: center;
  user-select: none;
  cursor: grab;
  transition: transform 100ms ease-in-out;
  transform: scale(2);
  &:hover {
    transform: scale(2.4);
  }
}

formly-list-section > .form-group {
  .form-array > formly-field > formly-group {
    width: 100%
  }
  & > .form-array > formly-field > formly-wrapper-form-field {
    & > label {
      cursor: grab;
      transition: transform 100ms ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
    & > label,
    & > .field > .form-array {
      margin: 0 4px;
    }
  }

  .cdk-drag-placeholder {
    opacity: 0.5;
    & > .cdk-drag-handle,
    & > formly-field > formly-wrapper-form-field > .cdk-drag-handle {
      visibility: hidden;
    }
  }
}

formly-list-section .form-array:not(:nth-child(2)) > formly-field > formly-group {
  border-top: 0.5px dashed var(--border);
}

.list-drag.cdk-drag-preview {
  overflow: visible;
  cursor: grabbing !important;
  & > formly-field > formly-group,
  .field,
  button {
    visibility: hidden;
  }
}

formly-field-radio, formly-field-multicheckbox {
  margin: 0;
  display: grid;
  grid-template-columns: min-content auto;
  * {
    margin: 4px;
  }
  label {
    text-align: left;
  }
}

.plugins-form {
  & > * {
    display: block;
  }

  .form {
    padding-bottom: 0 !important;
    max-width: 100%;
  }

  details.advanced {
    margin: 8px -8px;
    .form {
      padding: 16px;
    }
  }

  details.mini:not([open]) {
    float: right;
    margin: -4px 4px 4px 0 !important;
    summary > span {
      float: none;
      border: none !important;
    }
  }

  details.mini[open] {
    float: none;
    margin: -23px 4px 4px 0 !important;
    summary > span {
      float: right;
      border: none !important;
    }
  }

  .ribbon-margin {
    height: 0;
    transition: height 0.2s linear;
  }

  .floating-ribbons {
    right: 0;
    top: 0;
  }
}

.listbox span {
  display: flex;

  span {
    flex: 1;
    padding: 2px;
    border: 1px solid transparent;
  }

  .selected {
    border: 1px dotted var(--border);
  }
}

.page-controls {
  line-height: 24px;
  padding: 2px;
  margin: 10px;

  .disabled {
    pointer-events: none;
    text-decoration: none;

    &:link, &:active, &:visited {
      color: var(--text) !important;
    }
  }
}

.thumbnail {
  margin-right: 10px;
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: white;
  overflow: hidden;

  &.default-thumbnail {
    background-image: url("~/assets/default-thumbnail.png");
  }
}

.list-item {
  display: block;
  max-width: 100vw;
  border: 1px solid transparent;
  margin: 2px;
  padding: 2px;

  .link-below {
    display: flex;
  }
}

.list-item:not(.full-page):not(.expand),
.kanban-card,
.note,
.comment {
  &.last-selected {
    border: 1px dashed var(--border);
  }
}

.email {
  .thumbnail, .link {
    display: none;
  }
  .list-item .expand {
    border: none;
  }
}

.ext {
  display: block;
}

.ref.thread,
.ref.plugin_thread  {
  textarea {
    max-width: 800px;
  }
}

.thread > .ref-list > .list-container > .ref,
.thread .ref.plugin_thread:not(.ref-list-item),
.md .ref.plugin_thread:not(.ref-list-item),
.parent-ref.thread,
.full-page.ref.thread {
  & > .embed > .expand,
  & > .row > .stack > .link-below > .stack > .embed > .expand {
    border: none !important;
  }
}

.bubble .ref.expand {
  border: none !important;
}

.ref.upload, .ext.upload {
  --upload-border: 30px;
  --upload-border-margin: -6px;
  position: relative;
  padding-left: calc(var(--upload-border));
  &:before {
    position: absolute;
    pointer-events: none;
    content: ' ';
    height: 100%;
    width: var(--upload-border);
    left: var(--upload-border-margin);
    box-sizing: border-box;
    border: var(--upload-border) solid transparent;
    border-left: var(--upload-border) solid var(--warning);
  }
  &.exists {
    &:before {
      border-left: var(--upload-border) solid var(--error);
    }
  }
}

.thread > .ref-list > .list-container > .ref,
.thread .ref.plugin_thread:not(.ref-list-item),
.md .ref.plugin_thread:not(.ref-list-item),
.parent-ref.thread,
.full-page.ref.thread {
  max-width: 60em;
  margin: 8px;
  padding: 16px;
  --thread-sent: rgba(39, 78, 124, 0.58);
  --thread-recieved: rgba(128, 128, 128, 0.5);
  border-radius: 32px;
  overflow: hidden;
  &.sent {
    margin-left: 40px;
    background-color: var(--thread-sent);
    border-bottom-right-radius: 2px;
    border: 1px solid rgba(46, 87, 159, 0.3);
  }
  &:not(.sent) {
    background-color: var(--thread-recieved);
    border-bottom-left-radius: 2px;
    border: 1px solid rgba(157, 157, 157, 0.3);
  }
  &.last-selected {
    border: 1px dashed var(--border);
  }
  & > .row > .thumbnail {
    display: none;
  }
  & > .row > .stack > .link-below > .stack {
    & > .info, & > .actions {
      margin-left: -8px;
      padding: 0 8px 3px;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
    }
  }
}
.parent-ref.thread {
  & > .row > .stack > .link-below > .toggle {
    display: none;
  }
}
.thread > .ref-list > .list-container > .ref,
.full-page.ref.thread {
  & > .row > .stack > .comment-title,
  & > .row > .stack > .link-below > .toggle {
    display: none;
  }
}
.thread > .ref-list > .list-container {
  & > .list-number {
    font-size: 60%;
    padding: 0;
    line-height: unset;
    margin-left: -8px;
    transform: translate(12px, 8px);
  }
  & > .ref > .row {
    & > .stack > .link.redundant,
    & > .thumbnail {
      display: none;
    }
  }
}

.stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-width: 0;
}

.toggle {
  user-select: none;
  display: block;
  clear: both;
  margin: 4px 8px 4px 2px;
  flex-shrink: 0;
  width: 24px !important;
  height: 24px !important;
  min-width: unset !important;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  border: 1px solid var(--toggle-border);
  font-size: 18px;
  padding: 0;
  transition: transform 100ms;
  background-color: var(--toggle);
  &:hover {
    background-color: var(--toggle-accent);
    &:active {
      background-color: var(--toggle-active);
      transform: scale(0.99) rotate(2deg) translateY(0.5px);
    }
  }
  .toggle-plus,
  .toggle-x {
    position: relative;
  }

  &.actions-toggle {
    margin: 4px 0 4px 8px;
  }

  &.threads, &.comments, &.view {
    margin: 4px 8px 4px 8px;
  }

  &.inline, &.embed {
    display: inline-block;
    margin: 4px;
  }
}

.sidebar .toggle:active {
  transform: scale(0.99) translateY(1px);
}

.comment {
  margin-left: 10px;

  .voting {
    margin-top: 4px;
    margin-right: 2px;
    font-size: 80%;
    display: inline-block;

    * {
      transform: none !important;
    }
  }

  .comment-children {
    margin-left: 18px;
  }

  .comment-container {
    padding: 2px;
    border: 1px solid transparent;
    &:focus-visible {
      border: 1px dashed var(--border);
    }
  }

  .comment-body, .row > .comment-container > .actions {
    padding: 4px;
    margin-left: 20px;
  }

  .collapsed {
    font-style: italic;

    .comment-expand {
      font-style: normal;
    }
  }

  .info {
    .author {
      font-weight: bold;
    }
  }
}

.comment-reply, .comment-edit {
  button, .text-wrapper {
    margin: 5px;
  }
}

.hints {
  float: right;
  display: flex;
  font-size: 75%;
  color: var(--warning);

  label {
    margin-right: 10px;
    line-height: 13px;
    align-self: center;
    sup {
      margin: 0;
    }
  }
}

.link {
  .host {
    margin-left: 4px;
    font-size: 80%;
    color: var(--info);
  }
}

.info {
  font-size: 80%;
  color: var(--info);

  :link, :visited, a:active {
    color: var(--tag);
  }

  .collapsed {
    :link, :visited, a:active {
      color: var(--info);
    }
  }
}

.actions {
  padding: 2px 0;
  height: 14px;
  user-select: none;

  a, .fake-link {
    margin: 2px 6px 2px 0;
  }
}
.actions, .advanced-actions {
  font-size: 80%;
  font-weight: bold;
  white-space: nowrap;

  :link, :visited, a:active, .fake-link, .loading-dots {
    color: var(--info) !important;
  }

  input {
    margin: 0;
    font-size: 85%;
    height: 16px !important;
    &:invalid {
      color: var(--error);
    }
  }

  select {
    margin-top: -4px;
    min-height: 20px;
    height: 20px;
  }

  button {
    position: relative;
    top: 1px;
    margin: 0 6px;
    padding: 0 4px;
    height: 14px;
    min-width: 0 !important;
    min-height: 0 !important;
    line-height: 10px;
  }
}

.hotkey .action.force-delete .fake-link {
  color: var(--active) !important;
}

.ref.parent-ref {
  margin-left: 20px;
  .thumbnail {
    display: none;
  }
  .link {
    &:before {
      content: '⤴️';
      font-size: 80%;
      padding-right: 8px;
    }
    a {
      font-style: italic;
      font-size: 90%;
    }
    .host {
      font-size: 70%;
    }
  }
  .info {
    font-size: 70%;
  }
  .actions {
    display: none;
  }
}

.viewer-inline > * {
  margin: 4px;
}

.viewer-below > * {
  margin: 4px;
}

.expand {
  border-radius: 4px;
  margin: 2px;
  padding: 8px;
  max-width: min(100vw - 16px, 60em);

  .embed-expand {
    margin: auto;
  }
}

.ref, .form, .editor {
  .expand {
    border: 1px solid var(--border);
  }
}

.image-expand {
  height: 240px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.loading {
    border-radius: 7px;
    opacity: 50%;
    animation-duration: 4s;
    animation-name: image-loading;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes image-loading {
    from {
      box-shadow: inset 0 0 5px grey;
    }

    5% {
      box-shadow: inset 0 0 5px grey;
    }

    35% {
      box-shadow: inset 0 0 30px grey;
    }

    70% {
      box-shadow: inset 0 0 30px grey;
    }

    to {
      box-shadow: inset 0 0 5px grey;
    }
  }
}

.video-expand {
  height: 240px;
}

.audio-expand {
  width: calc(100% - 40px);
  max-width: 60em;
  margin: 10px 0;
}

.notification {
  filter: sepia(100%) saturate(400%) brightness(74%) hue-rotate(300deg);
}

.no-results {
  margin-left: 10px;
  color: var(--error);
}

.load-more {
  display: inline-block;
  font-size: 80%;
  font-weight: bold;
  padding: 10px;
  color: var(--info);
}

.empty-plugins {
  font-size: 80%;
  font-style: italic;
}

textarea {
  width: 504px;
  height: 100px;
}

.plugins-form textarea {
  width: 464px;
  height: 100px;
}

footer {
  clear: both;
  margin-top: 100px;
  .version {
    text-align: center;
  }
}

.login-popup {
  position: fixed;
  width: 100vw;
  z-index: 2;
  display: flex;
  justify-content: center;
  & > * {
    margin: 10px;
  }
}

.backup.buttons button, input.upload {
  margin: 10px;
}

.validations > div {
  margin: 4px;
}

.description {
  font-size: 80%;
}

.inline-ui {
  &, & > .md, & > .md > *:not(dialog) {
    display: inline !important;
    white-space: nowrap;
    & > br {
      display: none !important;
    }
  }
}

.md, .md blockquote {
  max-width: 60em;
  overflow-wrap: break-word;
  word-wrap: break-word;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  p {
    text-align: justify;
    & > app-nav > .tag {
      margin: 0;
    }
  }

  blockquote {
    color: var(--info);
    border-left: 2px solid var(--quote);
  }

  del {
    color: var(--info);
  }

  figcaption {
    margin: 4px;
    padding: 2px;
    font-weight: bold;
    font-size: 80%;
    font-style: italic;
  }

  pre {
    padding: 10px !important;
  }

  hr {
    border: none;
    height: 2px;
    background: var(--info);
    border-top: 1px groove var(--border);
    hr {
      border: none;
      border-top: 1px groove var(--border-accent);
    }
  }

  td, th {
    padding: 4px;
  }

  code {
    border-radius: 2px;
    border: 2px solid var(--bg);
    background: var(--bg);
  }

  pre > code {
    border-radius: unset;
    border: none;
    background: transparent;
  }

  table, th, tr, td {
    border: 1px solid var(--info);
    border-collapse: collapse;
  }

  .embed > * {
    margin: 0 auto;
  }
}

ngx-monaco-editor {
  position: relative;
  border: 1px solid var(--border);
  .editor-container {
    height: 100% !important;
  }
}

.kanban {
  padding: 4px;
  grid-gap: 8px;

  .column-title {
    justify-self: center;
    width: calc(100% - 32px);
  }
  .column-title, .swim-lane-title {
    margin: 8px 0;
    padding: 4px;
    border-radius: 12px !important;
    font-weight: bold;
    text-align: center;

    transform: scale(1);
    transform-origin: center;
    transition: transform 0.1s cubic-bezier(.47,1.64,.8,1.2);
    &:hover {
      z-index: 1;
      transform: scale(1.01);
    }
  }
  .kanban-column {
    justify-self: center;
    border: 1px solid transparent;

    &:hover {
      border: 1px dashed var(--border);
    }
  }

  .kanban-header {
    margin-top: 8px;
    .kanban-remove {
      border-radius: 4px;
      background-color: var(--placeholder);
    }
    &.cdk-drop-list-dragging {
      .kanban-remove {
        background-color: var(--deleting);
      }
    }

    .cdk-drag-placeholder {
      display: none;
    }
  }
}

.kanban-card-container,
.note-container {
  .row * {
    align-self: center;
  }
}

.kanban-card,
.note {
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  background-color: var(--card);
  .plugin_thread .toggle {
    background-color: var(--toggle-accent);
  }

  .md p {
    text-align: left;
  }
}

.kanban-card {
  &.unlocked {
    border-radius: 5px;
    border: 0.25px dashed var(--border-accent);
  }
  &:hover {
    filter: drop-shadow(0 0 0.5px var(--border));
  }
  &.cdk-drag-placeholder {
    background-color: var(--placeholder);
  }
  &.no-write {
    cursor: no-drop !important;

    &.cdk-drag-preview {
      pointer-events: auto !important;
      cursor: no-drop !important;
      * {
        pointer-events: none !important;
      }
    }
  }
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(.47,1.64,.8,1.2);
  &.unlocked {
    z-index: 1;
    transform: scale(1.05);
  }
  &:hover {
    z-index: 1;
    transform: scale(1.01);
  }
  &:not(.no-write) {
    cursor: grab;
  }
  .card-title, .thumbnail {
    margin-bottom: 4px;
  }
  &.cdk-drag-preview {
    transition: transform 0s;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  &.cdk-drag-placeholder {
    transition: transform 0s;
    * {
      visibility: hidden !important;
    }
  }
}

.form .todo-list > input {
  display: block !important;
}

.nested-form .todo-list {
  padding-bottom: 4px !important;
  & > input {
    display: block !important;
  }
  & > .spacer {
    display: none !important;
  }
}

.todo-item {
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  background-color: var(--card);

  textarea {
    height: 64px !important;
    resize: none;
  }

  &.unlocked {
    border-radius: 5px;
    border: 0.25px dashed var(--border-accent);
  }
  &:hover {
    filter: drop-shadow(0 0 0.5px var(--border));
  }
  &.cdk-drag-placeholder {
    background-color: var(--placeholder);
  }

  .md p {
    text-align: left;
    margin: 1px;
  }

  &.no-write {
    cursor: no-drop !important;

    &.cdk-drag-preview {
      pointer-events: auto !important;
      cursor: no-drop !important;
      * {
        pointer-events: none !important;
      }
    }
  }

  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(.47,1.64,.8,1.2);
  &.unlocked {
    z-index: 1;
    transform: scale(1.05);
  }
  &:hover {
    z-index: 1;
    transform: scale(1.01);
  }

  &:not(.no-write) {
    cursor: grab;
  }

  &.cdk-drag-preview {
    transition: transform 0s;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &.cdk-drag-placeholder {
    transition: transform 0s;
    * {
      visibility: hidden !important;
    }
  }
}

.blog {
  .blog-entry {
    .blog-title {
      max-width: 40em;
      word-wrap: break-word;
    }
    .blog-body {
      .expand {
        border: none;
      }
    }
    display: block;
    max-width: calc(100vw - 8px);
    overflow: hidden;
    border: 1px solid transparent;
    margin: 2px;
    padding: 2px;

    .row {
      margin-left: 10px;
    }

    .link-below {
      display: flex;
    }
  }
}

.chat {
  .odd > * {
    background-color: var(--bg-accent);
    backdrop-filter: blur(1px);
  }

  .gutter {
    opacity: 0.5;
  }

  .focused {
    margin: 4px;
  }

  .messages {
    max-height: 80vh;
    .cdk-virtual-scroll-content-wrapper {
      display: grid;
      grid-template-columns: min-content auto;
      width: 100%;
    }
  }
}

.desktop {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  max-width: 100vw;
  height: 90vh;
}

.file, .subfolder {
  display: block;
  padding: 6px;
  border-radius: 16px;
  &:hover, &:active, &.cdk-drag-dragging {
    z-index: 1000;
    box-shadow: inset 0 0 5px grey;
    backdrop-filter: blur(3px);
    transition: box-shadow 0.1s ease-out;
  }
  &.cdk-drag-dragging {
    box-shadow: inset 0 0 10px grey;
    transition: box-shadow 0.1s ease-in;
  }
  width: 128px;
  min-height: 128px;
  display: inline-block;
  user-select: none;
  text-align: center;
  .row {
    justify-content: center;
    a {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
  .thumbnail {
    width: 128px;
    height: 128px;
    line-height: 128px;
    font-size: 64px;
    cursor: default;
  }
  :focus, :focus-within {
    .folder-icon {
      filter: drop-shadow(0 0 2px var(--border));
    }
  }
}

.chess-piece {
  cursor: grab;
  text-align: center;
  vertical-align: middle;
  &.w {
    color: #DC9;
    filter: drop-shadow(1px 1px 1px black);
  }
  &.b {
    color: #111;
    filter: drop-shadow(0 0 1px rgba(200, 200, 200, 0.5));
  }
  &.cdk-drag-placeholder {
    display: none !important;
  }
  &.cdk-drag-preview {
    font-size: var(--drag-piece-size) !important;
  }
  &.disabled {
    cursor: default !important;
  }
}

.backgammon-piece {
  cursor: grab;
  text-align: center;
  vertical-align: middle;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1), scale 250ms cubic-bezier(0, 0, 0.2, 1);

  &.cdk-drag-preview {
    font-size: var(--drag-piece-size) !important;
    transform: scale(1.2);
  }
  &.disabled {
    cursor: default !important;
  }
}

.backgammon-spot, .backgammon-bar, .backgammon-off {
  .backgammon-piece.cdk-drag-placeholder {
    opacity: 0;
  }
  &:not(.drag-source) {
    .backgammon-piece.cdk-drag-placeholder {
      height: 0 !important;
    }
  }
}

.cdk-drop-list-dragging {
  .chess-piece {
    transform: scale(0.9) !important;
    transition: transform 0.1s ease-in-out;
  }
}
