// Mini: Smallest possible size
$mini-width: 380px;
// Mobile: Change tabs and forms
$mobile-width: 740px;
// Tablet: Move sidebar above
$tablet-width: 948px;

// TODO: CSS Vars

/* Tablet and Bigger Screens */
@media (min-width: $mobile-width) {
  .toggle {
    &.actions-toggle, &.view, &.threads, &.comments {
      display: none;
    }
  }

  .full-page-form {
    app-ref {
      max-width: 80%;
      margin: auto;
    }
    .form {
      margin: auto;
    }
  }

  .editor {
    &.add-button {
      min-width: calc(min(100vw, 538px));
    }
  }

  .mobile-block, .mobile-inline {
    display: none !important;
  }
}


/* Mobile Overrides */
@media not print {
/* Tablet and Smaller Screens */
@media (max-width: $tablet-width) {
  .sidebar {
    position: static !important;
    float: none !important;
    backdrop-filter: blur(1px) !important;
    border: none !important;
    margin-left: 0;

    details {
      max-height: 42px;
    }

    input[type=search] {
      width: 100%;
    }

    & > * {
      max-height: 1000px;
      overflow: hidden;
      transition: opacity .4s .1s,
      font-size .5s,
      margin .5s,
      padding .5s,
      border .5s,
      max-height .5s;
    }

    &:not(.expanded) {
      .hide {
        transition: opacity .5s,
        font-size .4s .1s,
        margin .4s .1s,
        padding .4s .1s,
        border .4s .1s,
        max-height .4s .1s;
        font-size: 0 !important;
        margin: 0 !important;
        max-height: 0 !important;
        opacity: 0 !important;
        padding: 0 !important;
        border: 0 !important;
      }
      .button-bar {
        flex-direction: row;
      }
      .submit-button {
        display: flex;
        flex-grow: 1;
        margin: 0;
        margin-right: 4px;
        align-items: center;
        &:last-child {
          margin-right: 1px;
        }
        span {
          width: 100%;
          text-align: center;
        }
        label {
          display: none;
        }
      }
    }
  }

  .todo-list, .kanban-column {
    padding-bottom: 4px !important;
    input {
      display: block !important;
    }
  }
}

/* Phone Screens */
@media (max-width: $mobile-width) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  button,
  select,
  select:focus,
  textarea {
    font-size: 16px;
  }
  .mobile-col {
    flex-direction: column;
  }
  .mobile-hide {
    display: none !important;
  }
  .mobile-block {
    display: block !important;
  }
  .mobile-inline {
    display: inline !important;
  }

  .subscription-bar {
    height: 20px;
    padding: 2px 8px 2px 0;

    .subs {
      line-height: 20px;
      font-size: 13px;
      margin: 0 !important;
      padding: 0;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 6px, white 18px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
    }

    .light-toggle {
      font-size: 13px;
      padding: 2px 20px 15px 22px;
    }
  }

  .tabs {
    h5 {
      flex-basis: 100%;
    }
  }

  .editor, .summary-box {
    background-color: unset !important;
    backdrop-filter: unset !important;
    box-shadow: unset !important;
    border: 1px solid transparent !important;
  }

  .toolbar {
    overflow-x: scroll;
  }

  .ref, .comment-edit, .comment-reply, .full-page-form {
    .editor, .summary-box {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .editor {
    &.add-button {
      &>* {
        margin: 4px;
      }
    }
  }

  .thread > .ref-list > .list-container > .ref,
  .md .ref.plugin_thread:not(.ref-list-item),
  .thread .ref.plugin_thread:not(.ref-list-item),
  .parent-ref.thread,
  .full-page.ref.thread {
    margin: 4px 6px 4px 4px;
    padding: 0;
    .thumbnail,
    .toggle.actions-toggle,
    .toggle.view,
    .toggle.threads,
    .toggle.comments {
      display: none;
    }
    &:not(.sent) {
      justify-self: left;
    }
    &.sent {
      margin-left: 4px;
      justify-self: right;
    }
    & > .row > .stack {
      padding: 8px;
      & > .link-below > .stack > .info,
      & > .link-below > .stack > .actions,
      & > .link  {
        margin-left: 0;
        transition: none;
        height: 28px;
        line-height: 28px;
        padding: 0 8px;
      }
      & > .link  {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .buttons.right {
    padding-right: 27px;
  }

  .list-container {
    .list-number {
      opacity: 0;
      width: 0;
      min-width: 0;
      padding: 0;
      line-height: 0;
    }
    .link {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  .form {
    padding: 0;
  }

  textarea {
    margin: 0;
    resize: vertical;
    width: 100%;
  }

  .full-page-form .editor textarea {
    margin-left: 0;
  }

  .app-comment-reply, app-comment-edit {
    .editor {
      width: calc(100% - 11px);
      textarea {
        margin-left: -11px;
        width: calc(100% + 5px);
      }
    }
  }

  .editor-label, .add-plugins-label {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > label {
      margin-right: 20px;
    }
    & > .select-plugin {
      flex: 1;
    }
  }

  .json-editor ngx-monaco-editor {
    margin: 0 0 0 -10px;
    width: 100vw !important;
  }

  .plugins-form {
    .json-editor ngx-monaco-editor,
    textarea {
      width: calc(100vw - 40px) !important;
    }
  }

  .floating-ribbons > div {
    right: calc(23px - 100%);
  }

  ngx-monaco-editor {
    width: 100vw !important;
    max-height: 50vh;
  }

  .resize-handle:after {
    content: '⬍';
    font-size: 175%;
    width: 24px;
    height: 48px;
    line-height: 48px;
    background-image: none;
    right: 22px;
    bottom: -25px;
  }

  .voting {
    font-size: 170%;
    * {
      transform: none !important;
    }
  }

  .comment {
    margin-left: 2px;

    .voting {
      margin-top: 10px;
    }

    .comment-children {
      margin-left: 2px;
    }
  }

  .comment-reply, .comment-edit {
    width: min(100%, 100vw - 16px);
  }

  .embed:not(.comment-body) {
    position: relative;
    left: 0;
    margin: 0;
    width: min(100%, 100vw - 16px);
  }

  .audio-expand {
    width: 100%;
    margin: 10px 0;
  }

  video {
    width: 100vw;
    height: 100vh;
  }

  .full-page {
    .embed {
      width: calc(100vw - 10px);
    }
    &.thread .embed {
      width: unset;
    }
  }

  .info, .actions, .advanced-actions * {
    font-size: 16px;
    line-height: 28px;
    height: 28px;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    a, .fake-link {
      line-height: 28px;
    }
  }

  .ref {
    max-width: 100vw;
    .row {
      max-width: calc(100vw - 12px);
    }
    .info, .actions {
      height: 0;
      margin-left: -8px;
      padding: 0 8px;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));

      &.expanded {
        transition: height .5s;
        height: 32px;
        padding-top: 2px;
      }
      &.not-expanded {
        transition: height .5s;
      }
    }
    &.full-page > .row > .stack > .link-below {
      & > .stack > * {
        &.info, &.actions {
          height: 32px !important;
          transition: none !important;
        }
      }
      & > .toggle {
        &.actions-toggle, &.view, &.threads, &.comments {
          display: none !important;
        }
      }
    }
  }

  .show-more {
    opacity: 1 !important;
  }

  .expand {
    border: none !important;
  }

  .thread .image-expand {
    width: calc(100vw - 36px);
  }

  .fill-editor {
    margin: 0 !important;
  }

  .md {
    blockquote {
      margin-right: auto;
    }
  }

  .kanban-header {
    .kanban-options > div {
        position: relative !important;
    }
  }

  .badges .badge {
    transform: none !important;
    filter: none !important;
    border: 1px solid transparent !important;
    &.filtering {
      transform: translate(1px, -1px) !important;
      filter: drop-shadow(0 0 0.5px white) drop-shadow(1px 1px 0.5px black) !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
    &:active {
      transform: scale(1.1) !important;
      filter: drop-shadow(-1px -1px 0.5px black) !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .cdk-overlay-pane .editor textarea {
    margin-left: 2px;
  }
  .form, .full-page-upload .list-container {
    max-width: calc(100vw - 8px);
    display: flex !important;
    flex-direction: column;

    label {
      text-align: left !important;
    }
  }
}

/* Smallest possible */
@media (max-width: $mini-width) {
  .mini-col {
    flex-direction: column;
    &.reverse {
      flex-direction: column-reverse;
    }
    & > * {
      width: 100% !important;
      margin: 4px 0;
    }
  }
}
}
