body.android {
  .toggle {
    .toggle-x {
      top: -1px;
    }
  }
  body.fullscreen {
    & > * {
      display: block;
    }
  }
}
